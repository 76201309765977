const mutations = {
  LOGIN(state, data) {
    state.isLogin = true;
    localStorage.setItem("accesstoken", data);
  },
  LOGOUT(state) {
    state.isLogin = false;
    state.isAdmin = false;
    state.email = "";
    state.firstName = "";
    state.lastName = "";
    state.type = "";
    state.id = null;
    state._id = null;
    localStorage.removeItem("accesstoken");
  },
  SET_NAVBAR(state, data) {
    state.navbarState = data;
  },
  SET_NAVBAR_VISIBLE(state, data) {
    state.navVisible = data;
  },
  SET_FOOTER(state, data) {
    state.footerVisible = data;
  },

  SET_USER_INFO(state, data) {
    if (data.email) {
      state.email = data.email;
    }
    if (data.id) {
      state.id = data.id;
    }
    if (data.userId) {
      state.userId = data.userId;
    }
    if (data.isAdmin) {
      state.isAdmin = data.isAdmin;
    }
    if (data.firstName) {
      state.firstName = data.firstName;
    }
    if (data.lastName) {
      state.lastName = data.lastName;
    }
    if (data.type) {
      state.type = data.type;
    }
  },
};
export default mutations;
