import axios from "axios";
import { baseUrl, headers } from "../config/env";

export function createUser(data) {
  return axios.post(baseUrl + "/accounts/join", data, { headers });
}

// export function fetchUserDetails(userId) {
//   return axios.get(baseUrl + `/accounts/detail/${userId}`, { headers });
// }

export function fetchProfile() {
  return axios.get(baseUrl + `/accounts/profile`, { headers });
}

// export function deleteUser(userId) {
//   return axios.delete(baseUrl + `/admin/user/${userId}`, {
//     headers,
//   });
// }

export function updateUser(data) {
  return axios.put(baseUrl + `/accounts/profile`, data, { headers });
}

// export function fetchUserList(params) {
//   return axios.get(baseUrl + `/admin/userList`, { params, headers });
// }

export function login(data) {
  return axios.post(baseUrl + "/accounts/login", data, { headers });
}
export function createGooglelogin(data) {
  return axios.post(baseUrl + "/auth/google/credential", data, { headers });
}

// export function createAccountId(data) {
//   return axios.post(baseUrl + "/accounts/findId", data);
// }

// //회원탈퇴
export function createWithdraw() {
  return axios.put(baseUrl + "/accounts/withdraw", "", { headers });
}

export function updateUserPwd(data) {
  return axios.put(baseUrl + `/accounts/user/pwd`, data, { headers });
}

export function updatePwd(data) {
  return axios.put(baseUrl + `/accounts/pwd`, data, { headers });
}

export function createCertCode(data) {
  return axios.post(baseUrl + `/auth/email`, data, { headers });
}

export function verifyEmail(data) {
  return axios.post(baseUrl + `/auth/email/verify`, data, { headers });
}
