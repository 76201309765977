export const baseUrl = "https://api.doctagen.com";
// export const baseUrl = "http://localhost:3000";
export const headers = {
  "Content-Type": "application/json;charset=UTF-8",
  Accept: "application/json",
  "Access-Control-Allow-Origin": "*",
  "X-Content-Type-Options": "nosniff",
  authorization: localStorage.getItem("accesstoken"),
};
// export const CALL_CENTER = "112";
