const state = {
  id: null,
  userId: null,
  email: "", //이메일
  firstName: "",
  lastName: "",
  // username: "",
  isLogin: false,
  navVisible: true,
  footerVisible: true,
  navbarState: 0,
  isAdmin: false,
};

export default state;
