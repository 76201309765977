<template>
  <header id="header">
    <div class="header__inner">
      <a href="/" class="logo"></a>
      <div class="header__btns">
        <a href="/" class="btn_text btn_pc">Home</a>
        <a href="mailto:doctagen.contact@gmail.com" class="btn_text btn_pc"
          >Contact us</a
        >
        <a v-if="!isLogin" href="/login" class="btn_pc btn_signin">Sign in</a>
        <a v-if="isLogin" href="/mypage" class="btn_pc btn_signin">Mypage</a>
        <a v-if="isAdmin" href="/admin/dashboard" class="btn_pc btn_signin"
          >ADMIN</a
        >
        <a
          href="mailto:doctagen.contact@gmail.com"
          class="btn_mobile contactus"
        ></a>
        <a v-if="!isLogin" href="/login" class="btn_mobile signin"></a>
        <a v-if="isLogin" href="/mypage" class="btn_mobile signin"></a>
      </div>
    </div>
  </header>
</template>

<script>
import { mapState } from "vuex";
import { baseUrl } from "@/config/env";
export default {
  data() {
    return {
      baseUrl: baseUrl,
    };
  },
  computed: {
    ...mapState([
      "isLogin",
      "firstName",
      "lastName",
      "username",
      "email",
      "isAdmin",
      "level",
    ]),
  },
  methods: {
    handleLogout() {
      let result = confirm("Are you sure you want to log out?");
      if (result == false) {
        return;
      }
      this.$store.dispatch("LOGOUT");
    },
    handleMove(path) {
      this.$router.push(path);
      this.handleClose();
    },
    handlePage(category) {
      this.$router.push({ name: "surveyList", params: { category: category } });
      this.handleClose();
    },
    handleOpen() {
      document.querySelector(".inner").classList.add("active");
      document.querySelector(".inner").classList.remove("hide");
    },
    handleClose() {
      document.querySelector(".inner").classList.add("hide");
      document.querySelector(".inner").classList.remove("active");
    },
    handleFilter() {
      document.querySelector(".down").classList.toggle("active");
      document.querySelector(".sub").classList.toggle("active");
    },
    logout() {
      let result = confirm("Are you sure you want to log out?");
      if (result == false) {
        return;
      }
      if (this.type == "GOOGLE") {
        window.google.accounts.id.disableAutoSelect();
      }
      this.$store.dispatch("LOGOUT");
    },
  },
};
</script>
