import Vue from "vue";
import VueRouter from "vue-router";
// import store from "../store";
Vue.use(VueRouter);

const requireAuth = () => (to, from, next) => {
  let accesstoken = localStorage.getItem("accesstoken");
  if (accesstoken == null || accesstoken == undefined) {
    alert("Please login to your account.");
    next("/");
  } else {
    next();
  }
  next();
};

const requireAdmin = () => (to, from, next) => {
  let accesstoken = localStorage.getItem("accesstoken");
  // if (accesstoken && store.state.isAdmin) {
  if (accesstoken) {
    next();
  } else {
    next("/");
  }
};

// const redirect = () => (to, from, next) => {
//   let accesstoken = localStorage.getItem("accesstoken");
//   if (accesstoken) {
//     next("/");
//   } else {
//     next();
//   }
// };

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/Home.vue"),
  },
  {
    path: "/list",
    name: "list",
    component: () => import("../views/List.vue"),
  },
  {
    path: "/detail",
    name: "detail",
    component: () => import("../views/Detail.vue"),
  },

  {
    path: "/account",
    name: "account",
    component: () => import("../views/account/Container.vue"),
    children: [
      {
        path: "/login",
        name: "login",
        component: () => import("../views/account/Login.vue"),
        // beforeEnter: redirect(),
      },
      {
        path: "/join",
        name: "join",
        component: () => import("../views/account/Join.vue"),
        // beforeEnter: redirect(),
      },
      {
        path: "/joinDone",
        name: "joinDone",
        component: () => import("../views/account/JoinDone.vue"),
      },
      // {
      //   path: "/findEmail",
      //   name: "findEmail",
      //   component: () => import("../views/account/FindEmail.vue"),
      // },
      // {
      //   path: "/findEmailDone",
      //   name: "findEmailDone",
      //   component: () => import("../views/account/FindEmailDone.vue"),
      // },
      {
        path: "/findPassword",
        name: "findPassword",
        component: () => import("../views/account/FindPassword.vue"),
      },
      {
        path: "/changePassword",
        name: "changePassword",
        component: () => import("../views/account/ChangePassword.vue"),
      },
      {
        path: "/changePasswordDone",
        name: "changePasswordDone",
        component: () => import("../views/account/ChangePasswordDone.vue"),
      },
    ],
  },

  {
    path: "/error503",
    name: "error503",
    component: () => import("../views/pop/Error503.vue"),
    // beforeEnter: requireAdmin(),
  },
  {
    path: "/admin",
    name: "admin",
    component: () => import("../views/admin/Container.vue"),
    beforeEnter: requireAdmin(),
    children: [
      {
        path: "dashboard",
        name: "dashboard",
        component: () => import("../views/admin/Dashboard.vue"),
      },
      {
        path: "users",
        name: "users",
        component: () => import("../views/admin/Users.vue"),
      },
      {
        path: "userDetail",
        name: "userDetail",
        component: () => import("../views/admin/UserDetail.vue"),
      },
      // {
      //   path: "posts",
      //   name: "posts",
      //   component: () => import("../views/admin/Posts.vue"),
      // },
      // {
      //   path: "posts/register",
      //   name: "postsRegister",
      //   component: () => import("../views/admin/PostRegister.vue"),
      // },
      // {
      //   path: "posts/detail/:id",
      //   name: "postDetail",
      //   component: () => import("../views/admin/PostDetail.vue"),
      // },
    ],
  },
  {
    path: "/mypage",
    name: "mypage",
    component: () => import("../views/mypage/Mypage.vue"),
    beforeEnter: requireAuth(),
  },
  // {
  //   path: "/mypage/edit",
  //   name: "edit",
  //   component: () => import("../views/mypage/UserInfo.vue"),
  //   beforeEnter: requireAuth(),
  // },
  {
    path: "/terms",
    name: "terms",
    component: () => import("../views/terms/UseTerms.vue"),
  },
  {
    path: "/privacy",
    name: "privacy",
    component: () => import("../views/terms/TermsPrivacy.vue"),
  },
  {
    path: "*",
    name: "error404",
    component: () => import("../views/pop/Error404.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;
